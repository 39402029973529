import * as React from 'react'
import Nav from "../components/nav"
import Footer from "../components/footer"
import Hero from "../components/hero"
import {Helmet} from "react-helmet"
import OpenGraphLogo from "../images/open-graph-logo.png"

function TennisPerformance() {
    return (
        <div>
            <Helmet>
                <title>Tennis Performance & Injury Prevention | Kinetic Impact</title>
                <meta property="og:title" content="Tennis Performance & Injury Prevention | Kinetic Impact" />
                <meta property="og:image" content={OpenGraphLogo} />
                <meta property="og:description" content="Expert tennis-specific conditioning, serve mechanics optimization, and injury prevention. Specialized programs for court movement, rotator cuff strengthening, and tennis elbow treatment." />
                <meta name="description" content="Enhance your tennis performance with professional movement training, serve mechanics optimization, and injury prevention. Our experts help players improve conditioning, prevent tennis elbow, and optimize recovery between matches." />
                <meta name="keywords" content="tennis performance, tennis-specific conditioning, serve mechanics, court movement patterns, rotator cuff strengthening, tennis injury prevention, tennis elbow, match recovery" />
            </Helmet>
            <Nav/>
            <Hero href={"https://kineticimpact.janeapp.com/#/1st-timers"} 
                  header={"Elevate Your Tennis Game with Expert Performance Training"}>
                <div id={"content-start"} className={"flex flex-col justify center mx-auto px-4 lg:pt-8 pt-4 text-lg bg-white rounded-xl"}>
                    <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                        Looking to take your tennis game to the next level? Our tennis-specific conditioning programs help you develop the strength, mobility, and endurance needed for peak performance on the court.
                    </p>
                    
                    <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                        From serve mechanics optimization to preventing tennis elbow, our expert team understands the unique demands of tennis and provides comprehensive support for players of all levels.
                    </p>

                    <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto w-full"}>
                        Let's work together to enhance your game and keep you performing at your best match after match.
                    </p>

                    <a className={"mx-auto"} href={"https://kineticimpact.janeapp.com/#/1st-timers"}>
                        <button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>
                            Let's Work Together!
                        </button>
                    </a>

                    <h2 className={"lg:text-5xl text-4xl text-center pt-16 pb-10"}>Comprehensive Tennis Training</h2>

                    <div className={"flex flex-col justify-center"}>
                        <h3 className={"text-center text-3xl pb-4 pt-4"}>Movement Analysis</h3>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Master efficient court movement patterns through expert analysis and training. We'll help you develop the quick, controlled movements essential for better court coverage and shot preparation.
                        </p>

                        <h3 className={"text-center text-3xl pb-4 pt-4"}>Serve Optimization</h3>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Improve your serve mechanics optimization through targeted strength training and movement analysis. Our approach focuses on developing power while protecting your shoulder through proper rotator cuff strengthening.
                        </p>

                        <h3 className={"text-center text-3xl pb-4 pt-4"}>Injury Prevention</h3>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Our tennis injury prevention program addresses common issues like tennis elbow and shoulder injuries before they start. Learn proper techniques and exercises to maintain joint health during intense training periods.
                        </p>

                        <h3 className={"text-center text-3xl pb-4 pt-4"}>Performance Recovery</h3>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Master effective strategies for recovery between matches to maintain peak performance throughout tournaments. We'll help you develop a comprehensive recovery routine that keeps you fresh and ready to compete.
                        </p>
                    </div>

                    <a className={"mx-auto"} href={"https://kineticimpact.janeapp.com/#/1st-timers"}>
                        <button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>
                            Start Your Training Today
                        </button>
                    </a>

                    <h2 className={"lg:text-5xl text-4xl text-center pt-16 pb-10"}>Key Program Components</h2>

                    <div className={"flex flex-col justify-center"}>
                        <h3 className={"text-center text-3xl pb-4 pt-4"}>Tennis-Specific Conditioning</h3>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Develop the strength, speed, and endurance needed for competitive tennis. Our conditioning programs are tailored to the unique demands of tennis, helping you perform at your best throughout long matches.
                        </p>

                        <h3 className={"text-center text-3xl pb-4 pt-4"}>Joint Protection</h3>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Prevent and manage tennis elbow through specialized exercises and techniques. Our comprehensive approach includes rotator cuff strengthening and proper load management to protect vulnerable joints.
                        </p>

                        <h3 className={"text-center text-3xl pb-4 pt-4"}>Movement Training</h3>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Perfect your court movement patterns with drills designed to improve agility, speed, and reaction time. Learn to move efficiently while maintaining proper positioning for optimal shot execution.
                        </p>

                        <h3 className={"text-center text-3xl pb-4 pt-4"}>Recovery Protocols</h3>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Maximize your recovery between matches with proven techniques and strategies. Our comprehensive recovery protocols help you maintain performance during tournaments and intensive training periods.
                        </p>
                    </div>

                    <a className={"mx-auto"} href={"https://kineticimpact.janeapp.com/#/1st-timers"}>
                        <button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10 mb-4"}>
                            Elevate Your Tennis Game Today
                        </button>
                    </a>
                </div>
            </Hero>
            <Footer/>
        </div>
    )
}

export default TennisPerformance